import React from 'react';
import PropTypes from 'prop-types';

import API from '../../shared/api';

class DeviceSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = { selected: null };
    }

    _onClick = e => {
        const { devices, onSelect } = this.props;
        const idx = parseInt(e.currentTarget.dataset.idx);

        if (idx < devices.length) {
            this.setState({ selected: idx });
            onSelect(devices[idx]);
        }
    };

    render() {
        const { devices } = this.props;
        const { selected } = this.state;
        return (
            <div className="device-list list-group">
                {devices.map((device, idx) => (
                    <div
                        key={device.id}
                        className={`device list-group-item ${selected === idx ? 'selected' : ''}`}
                        data-idx={idx}
                        onClick={this._onClick}
                    >
                        <div className="row">
                            <div className="col-md-3 text-center">
                                <img
                                    className="device-image"
                                    alt='Device'
                                    src={`https://techsupport.orbitbhyve.com/images/${device.hardware_version.substr(0, 4).toLowerCase()}.png`}
                                />
                            </div>
                            <div className="col-md-9">
                                <div className="row">
                                    <span className="device-name col-md-6">{device.name}</span>
                                    <span className="reference col-md-6">{device.reference}</span>
                                </div>
                                <div className="row">
                                    <span className="version col-md-6">{device.hardware_version}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

DeviceSelector.propTypes = {
    onSelect: PropTypes.func.isRequired,
    devices: PropTypes.array.isRequired,
};

class OrbitLogin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            logginMessage: null,
            apiKeyMessage: null,
            devicesMessage: null,
            email: '',
            password: '',
            devices: [],

            token: null,
            userId: null,
            deviceId: null,
        };
    }

    _doLogin = (e) => {
        e.preventDefault();

        this.setState({
            token: null,
            userId: null,
            deviceId: null,
        });

        const { email, password } = this.state;
        if (email && password) {
            window.$('#waiting-modal').modal({ backdrop:'static', keyboard:false });
            this.setState({
                logginMessage: 'Logging in...',
            });

            const api = new API();
            api.init(email, password)
            .then(({ token, userId }) => {
                const t1 = token.substring(0, 15);
                const t2 = token.substring(token.length - 15);
                this.setState({
                    token, userId,
                    logginMessage: `Logged in. User ID: ${userId}`,
                    apiKeyMessage: `API Token: ${t1}...${t2}`,
                    devicesMessage: 'Fetching device list...',
                });

                return api.getDevices();
            }).then(devices => {
                this.setState({ devices }, () => {
                    window.$('#devices-modal').modal({ backdrop:'static', keyboard:false });
                });
            });
        }
    };

    _deviceSelected = () => {
        const { token, userId, deviceId } = this.state;

        window.$('#waiting-modal').modal('hide');
        this.props.onComplete({ apiKey: token, userId, deviceId });
    };

    _setDevice = device => {
        this.setState({
            deviceId: device.id,
            devicesMessage: `Device: ${device.id} (${device.name})`,
        });
    }

    _onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { logginMessage, apiKeyMessage, devicesMessage, email, password } = this.state;

        const isInvalid = (email === '' || password === '');

        return (
            <div>
                <div id="waiting-modal" className="modal fade" role="dialog" tabIndex="-1">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Communicating with Orbit</h4>
                            </div>
                            <div className="modal-body">
                                { logginMessage ? <p>{logginMessage}</p> : null }
                                { apiKeyMessage ? <p>{apiKeyMessage}</p> : null }
                                { devicesMessage ? <p>{devicesMessage}</p> : null }
                            </div>
                        </div>
                    </div>
                </div>

                <div id="devices-modal" className="modal fade" role="dialog" tabIndex="-1">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Pick the device you want to manage</h4>
                            </div>
                            <div className="modal-body">
                                <DeviceSelector
                                    devices={this.state.devices}
                                    onSelect={this._setDevice}
                                />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={!this.state.deviceId}
                                    data-dismiss="modal"
                                    onClick={this._deviceSelected}
                                >
                                    Select Device
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <form className="form-signin" onSubmit={this._doLogin}>
                        <h2 className="form-signin-heading">Login to Orbit</h2>
                        <small className="form-signin-subheading">This is only used to get your API key and is not stored.</small>
                        <label htmlFor="username" className="sr-only">Orbit Username</label>
                        <input
                            name="email"
                            type="text"
                            value={email}
                            onChange={this._onChange}
                            className="form-control username"
                            placeholder="Orbit Username/Email"
                            required
                            autoFocus
                        />
                        <label htmlFor="password" className="sr-only">Orbit Password</label>
                        <input
                            name="password"
                            type="password"
                            value={password}
                            onChange={this._onChange}
                            className="form-control password"
                            placeholder="Orbit Password"
                            required
                        />
                        <button
                            type="submit"
                            disabled={isInvalid}
                            className="btn btn-lg btn-primary btn-block"
                        >
                            Login
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

OrbitLogin.propTypes = {
    onComplete: PropTypes.func.isRequired,
};

export default OrbitLogin;
