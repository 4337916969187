function lazyLoad(cb) {
    let inst = null;

    return () => {
        if (inst === null) {
            inst = cb();
        }
        return inst;
    };
}

class LazyFirebaseLoader {
    constructor(app) {
        this._app = app;
    }

    get app() {
        return this._app;
    }

    get auth() {
        return lazyLoad(() => this._app.auth());
    }

    get authui() {
        return lazyLoad(() => new window.firebaseui.auth.AuthUI(this.auth()));
    }

    get firestore() {
        return lazyLoad(() => this._app.firestore());
    }
}

class Firebase {
    constructor() {
        const raw = window.firebase;

        this.auth = raw.auth;

        this.inst = new LazyFirebaseLoader(raw.app());
    }

    onAuthStateChanged = cb => this.inst.auth().onAuthStateChanged(cb);

    apiAuthCollection = () => this.inst.firestore().collection('api-auth');

    apiAuthDoc = uid => this.apiAuthCollection().doc(uid);
}

export default new Firebase();
