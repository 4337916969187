import React from 'react';
import UserContext from './context';

export function withUser(WrapperComponent) {
    return props => (
        <UserContext.Consumer>
            {user => <WrapperComponent {...props} user={user} />}
        </UserContext.Consumer>
    );
};
