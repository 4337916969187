import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withFirebase } from '../../lib/Firebase';
import { withUser } from '../../lib/Session';
import * as ROUTES from '../../constants/routes';

class Navigation extends React.Component {
    signOut = () => {
        this.props.firebase.inst.auth().signOut();
    }

    anonymousNav = () => (
        <ul className="nav navbar-nav navbar-right">
            <li><Link to={ROUTES.SIGNIN}>Sign In</Link></li>
        </ul>
    );

    authNav = () => {
        const { user } = this.props;
        return (
            <div>
                <ul className="nav navbar-nav">
                    <li>
                        <Link to={ROUTES.HOME}>Home</Link>
                    </li>
                    <li>
                        <Link to={ROUTES.SETUP}>IFTTT Setup Instructions</Link>
                    </li>
                </ul>
                <ul className="nav navbar-nav navbar-right">
                    <li>
                        <span className="navbar-text">Logged in as {user.displayName}</span>
                    </li>
                    <li>
                        <Link to={ROUTES.SIGNIN} onClick={this.signOut}>Sign Out</Link>
                    </li>
                </ul>
            </div>
        );
    };

    render() {
        const { user } = this.props;
        return (
            <nav className="navbar navbar-default navbar-fixed-top">
                <div className="container-fluid">
                    <div className="collapse navbar-collapse">
                    {user !== null
                        ? this.authNav()
                        : this.anonymousNav()
                    }
                    </div>
                </div>
            </nav>
        );
    }
}

Navigation.propsTypes = {
    user: PropTypes.object.isRequired,
    firebase: PropTypes.object.isRequired,
};

export default withUser(withFirebase(Navigation));
