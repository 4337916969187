import React from 'react';
import { Route } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import Navigation from '../Navigation';
import SetupPage from '../Setup';
import SignInPage from '../SignIn';
import HomePage from '../Home';

import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../lib/Firebase';
import { UserContext } from '../../lib/Session';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            sessionUser: this.props.firebase.inst.auth().currentUser,
        };

        this.authUnsub = null;
    }

    componentDidMount() {
        const { history } = this.props;

        this.authUnsub && this.authUnsub();
        this.authUnsub = this.props.firebase.onAuthStateChanged(u => {
            // Wait until we've loaded the user to determine what to do
            this.setState({ loading: false, sessionUser: u });

            if (u === null && this.props.location.pathname !== ROUTES.SIGNIN) {
                history.replace(ROUTES.SIGNIN);
            }
        });
    }

    componentWillUnmount() {
        this.authUnsub && this.authUnsub();
        this.authUnsub = null;
    }

    render() {
        const { loading } = this.state;
        if (loading) {
            return <h1>Loading</h1>;
        } else {
            return (
                <UserContext.Provider value={this.state.sessionUser}>
                    <Navigation />

                    <div className="content-container">
                        <Route exact path={ROUTES.HOME} component={HomePage} />
                        <Route path={ROUTES.SETUP} component={SetupPage} />
                        <Route path={ROUTES.SIGNIN} component={SignInPage} />
                    </div>
                </UserContext.Provider>
            );
        }
    }
}

export default withRouter(withFirebase(App));
