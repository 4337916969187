const axios = require('axios');
const WebSocket = require('ws');

const ENDPOINT = 'https://api.orbitbhyve.com/v1';

const WS_TIMEOUT = 5000;

class WebSocketProxy {
    constructor() {
        this._ws = null;
    }

    connect(token, deviceId) {
        return new Promise((resolve, reject) => {
            let connected = false;

            this._ws = new WebSocket(`${ENDPOINT}/events`);

            const origSend = this._ws.send.bind(this._ws);
            this._ws.send = (data, options, callback) => {
                if (typeof data === 'object') {
                    data = JSON.stringify(data);
                }
                origSend(data, options, callback);
            };

            this._ws.on('open', () => {
                this._ws.send({
                    event: 'app_connection',
                    orbit_session_token: token,
                    subscribe_device_id: deviceId,
                });

                connected = true;
                resolve(this._ws);
            });

            this._ws.on('error', msg => {
                if (!connected) {
                    this._ws.close();
                    reject(msg);
                }
            });
        });
    }
}

class API {
    constructor(config = {}) {
        this._token = config.apiKey || null;
        this._userId = config.userId || null;
        this._deviceId = config.deviceId || null;

        this._ws = new WebSocketProxy();

        axios.interceptors.request.use(config => {
            config.headers['orbit-api-key'] = this._token;
            config.headers['orbit-app-id'] = 'Orbit Support Dashboard';
            return config;
        });
    }

    init(email, password) {
        return axios.post(`${ENDPOINT}/session`, {
            session: {
                email,
                password,
            }
        }).then(rsp => {
            const { orbit_api_key, user_id } = rsp.data;
            this._token = orbit_api_key;
            this._userId = user_id;

            return {
                token: this._token,
                userId: this._userId,
            }
        });
    }

    getDevices() {
        if (!this._userId) {
            return Promise.reject(new Error('User ID not found'));
        }

        return axios.get(`${ENDPOINT}/devices`, {
            user_id: this._userId,
        }).then(rsp => {
            return rsp.data.map(device => ({
                id: device.id,
                name: device.name,
                reference: device.reference,
                hardware_version: device.hardware_version,
            }));
        });
    }

    _socketPayload(payload, completePredicate) {
        if (!this._token) {
            return Promise.reject(new Error('API Key not found'));
        }
        if (!this._deviceId) {
            return Promise.reject(new Error('Device ID not found'));
        }

        return new Promise((resolve, reject) => {
            this._ws.connect(this._token, this._deviceId)
            .then(conn => {
                const timeout = setTimeout(() => {
                    conn.close();
                    reject(new Error('Timeout waiting for response'));
                }, WS_TIMEOUT);

                conn.on('message', msg => {
                    const data = JSON.parse(msg);
                    if (completePredicate(data)) {
                        conn.close();
                        clearTimeout(timeout);
                        resolve();
                    }
                });

                conn.send(payload);
                return null;
            })
            .catch(e => reject(e));
        });
    }

    _setRainDelay(delay) {
        return this._socketPayload({
            event: 'rain_delay',
            device_id: this._deviceId,
            delay,
        }, msg => (msg.event === 'rain_delay' && msg.delay == delay)); // eslint-disable-line eqeqeq
    }

    setRainDelay(delay = 24) {
        return this._setRainDelay(delay);
    }

    clearRainDelay() {
        return this._setRainDelay(0);
    }

    _changeMode(mode, stations) {
        const payload = {
            event: 'change_mode',
            device_id: this._deviceId,
            timestamp: new Date().toISOString(),
            mode,
        };

        if (stations) {
            payload.stations = stations;
        }

        return this._socketPayload(payload, msg => msg.event === 'change_mode');
    }

    startZone(zoneId, minutes) {
        return this._changeMode('manual', [
            { station: zoneId, run_time: minutes }
        ]);
    }

    stopZone() {
        return this._changeMode('manual', []);
    }

    turnAuto() {
        return this._changeMode('auto');
    }

    turnOff() {
        return this._changeMode('off');
    }
}

module.exports = API;
