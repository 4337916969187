import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import OrbitLogin from './orbit-login';
import { withFirebase } from '../../lib/Firebase';
import { withUser } from '../../lib/Session';

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.dataWatcherUnsub = null;

        this.state = {
            error: null,
            apiAuth: undefined,
            loading: true,
            saving: false,
        };
    }

    componentDidMount() {
        const { user } = this.props;

        if (user) {
            this._watchUser(user);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            this._watchUser(this.props.user);
        }
    }

    componentWillUnmount() {
        this.dataWatcherUnsub && this.dataWatcherUnsub();
        this.dataWatcherUnsub = null;
    }

    _watchUser(user) {
        const { firebase } = this.props;

        this.dataWatcherUnsub && this.dataWatcherUnsub();
        this.dataWatcherUnsub = firebase.apiAuthDoc(user.uid)
        .onSnapshot({
            error: err => this.setState({
                loading: false,
                error: err,
            }),
            next: snapshot => {
                const apiAuth = (snapshot && snapshot.exists) ? snapshot.data() : null;
                this.setState({
                    apiAuth,
                    loading: false,
                });
            },
        });
    }

    showApiOptions() {
        const { apiAuth, savingToken } = this.state;

        let tokenBody = null;
        if (savingToken) {
            tokenBody = (
                <div className="container-fluid api-options">
                    <div className="row">
                        <label className="col-sm-5">Saving new token...</label>
                    </div>
                </div>
            );
        } else if (apiAuth.token) {
            tokenBody = (
                <div className="container-fluid api-options">
                    <div className="row">
                        <label className="col-sm-2">Security Token</label>
                        <div className="col-sm-10">{apiAuth.token}</div>
                    </div>
                    <div className="row text-center">
                        <button onClick={this._generateToken}>Regenerate Token</button>
                    </div>
                </div>
            );
        } else {
            tokenBody = (
                <div className="container-fluid api-options">
                    <div className="row text-center">
                        <button onClick={this._generateToken}>Generate Token</button>
                    </div>
                </div>
            );
        }

        return (
            <div className="container-fluid">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h3>Orbit Configuration</h3>
                    </div>
                    <div className="panel-body">
                        <div className="container-fluid orbit-options">
                            <div className="row">
                                <label className="col-sm-2">User ID</label>
                                <div className="col-sm-10 user-id">{apiAuth.userId}</div>
                            </div>
                            <div className="row">
                                <label className="col-sm-2">Device ID</label>
                                <div className="col-sm-10 device-id">{apiAuth.deviceId}</div>
                            </div>
                            <div className="row">
                                <label className="col-sm-2">Auth Key</label>
                                <div className="col-sm-10 api-key">{apiAuth.apiKey}</div>
                            </div>
                            <div className="row text-center">
                                <button onClick={this._reauthenticate}>Refresh Orbit Information</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h3>API Configuration</h3>
                    </div>
                    <div className="panel-body">
                        {tokenBody}
                    </div>
                </div>
            </div>
        );
    }

    _reauthenticate = (e) => {
        e.preventDefault();
        this.setState({ reauth: true });
    };

    _generateToken = async (e) => {
        e.preventDefault();

        const { firebase, user } = this.props;

        this.setState({ savingToken: true });

        let unique = false;
        const collection = firebase.apiAuthCollection(); // = firebase.apiAuthDoc(user.uid);
        do {
            const token = uuidv4();
            const existing = await collection.where('token', '==', token).get();
            if (existing.empty) {
                unique = true;
                firebase.apiAuthDoc(user.uid).set({ token }, { merge: true })
                .then(() => this.setState({
                    savingToken: false,
                }));
            }
        } while (!unique);
    }

    _updateApiAuth = (newAuth) => {
        const { firebase, user } = this.props;

        this.setState({ saving: true });

        firebase.apiAuthDoc(user.uid).set(newAuth, { merge: true })
        .then(() => this.setState({
            reauth: false,
            saving: false,
        }));
    }

    render() {
        const { error, saving, loading, apiAuth, reauth } = this.state;
        if (loading) {
            return (
                <h1>Loading...</h1>
            );
        } else if (error) {
            return (
                <h1 className="error">{error.message}</h1>
            );
        } else if (saving) {
            return (
                <div className="container">
                    <div className="row">
                        <span className="saving-message">Saving Config. Please Wait...</span>
                    </div>
                </div>
            );
        } else if (apiAuth && !reauth) {
            return this.showApiOptions();
        }

        return (
            <OrbitLogin
                onComplete={apiAuth => {
                    this._updateApiAuth(apiAuth);
                }}
            />
        );
    }
}

HomePage.propTypes = {
    firebase: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

export default withUser(withFirebase(HomePage));
