import React from 'react';
import PropTypes from 'prop-types';

import { HOME } from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../lib/Firebase';

class SignInPage extends React.Component {
    componentDidMount() {
        const { firebase, history } = this.props;

        firebase.inst.authui().start('#firebase-auth-ui', {
            signInSuccessUrl: HOME,
            callbacks: {
                signInSuccessWithAuthResult: authResult => {
                    if (authResult.user && authResult.user.uid) {
                        history.replace(HOME);
                        return false;
                    }
                    return true;
                  },
            },
            signInOptions: [
                firebase.auth.EmailAuthProvider.PROVIDER_ID,
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                firebase.auth.GithubAuthProvider.PROVIDER_ID
            ],
        });
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h3>Sign In</h3>
                    </div>
                    <div className="panel-body">
                        <div className="container-fluild">
                            <div className="row">
                                <div class="col-md-12">
                                    <h4>Sign in to configure your remote and generate your API token to use with IFTTT</h4>
                                </div>
                            </div>
                            <div className="row">
                                <hr />
                            </div>
                            <div id="firebase-auth-ui"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SignInPage.propTypes = {
    history: PropTypes.object.isRequired,
    firebase: PropTypes.object.isRequired,
};

export default withRouter(withFirebase(SignInPage));
