import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';

import App from './components/App';

import Firebase, { FirebaseContext } from './lib/Firebase';

ReactDOM.render(
    <FirebaseContext.Provider value={Firebase}>
        <Router forceRefresh={false}>
            <App />
        </Router>
    </FirebaseContext.Provider>,
    document.getElementById('root'));
