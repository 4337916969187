import React from 'react';
import FirebaseContext from './context';

export function withFirebase(WrappedComponent) {
    return props => (
        <FirebaseContext.Consumer>
            {firebase => <WrappedComponent {...props} firebase={firebase} />}
        </FirebaseContext.Consumer>
    );
};
