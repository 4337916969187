import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

const SetupPage = () => (
    <div className="container-fluid">
        <h2>IFTTT Setup Instructions</h2>

        <div className="row">
            <div className="col-md-12">
                Once you have created your <Link to={ROUTES.HOME}>Orbit and API Configuration</Link>, we can configure IFTTT to use a Voice Command to trigger a WebHook Action that will hit our API with the correct information.
            </div>
        </div>

        <h2>API End Points</h2>

        <table className="api-table table table-striped">
            <thead>
                <tr>
                    <th>Method</th>
                    <th>URL</th>
                    <th>Body</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>PUT</td>
                    <td>/raindelay</td>
                    <td><code>{'{ "delay": <value: int> }'}</code></td>
                    <td>Start a rain delay of <code>value</code> hours</td>
                </tr>
                <tr>
                    <td>DELETE</td>
                    <td>/raindelay</td>
                    <td></td>
                    <td>Remove a rain delay. Same as using 0 hours.</td>
                </tr>
                <tr>
                    <td>PUT</td>
                    <td>/watering/zone/&lt;zoneId:int&gt;</td>
                    <td><code>{'{ "minutes": <value: int> }'}</code></td>
                    <td>Start watering zone <code>zoneId</code> for <code>value</code> minutes</td>
                </tr>
                <tr>
                    <td>DELETE</td>
                    <td>/watering/zone/&lt;zoneId:int&gt;</td>
                    <td></td>
                    <td>Stop watering</td>
                </tr>
                <tr>
                    <td>DELETE</td>
                    <td>/watering</td>
                    <td></td>
                    <td>Stop all watering</td>
                </tr>
            </tbody>
        </table>

        <h2>Sample IFTTT Applet Setup</h2>

        <div className="trigger-panel panel panel-default">
            <div className="panel-heading">
                <div className="panel-title">Trigger</div>
            </div>
            <div className="panel-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-1">Service:</div>
                        <div className="col-md-3">Google Assistant</div>
                    </div>
                    <div className="row">
                        <div className="col-md-1">Trigger:</div>
                        <div className="col-md-3">Say a phrase with a number</div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">What do you want to say?:</div>
                        <div className="col-md-3">water zone #</div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">What do you want the system to say in response?:</div>
                        <div className="col-md-7">Aguamenti</div>
                    </div>
                </div>
            </div>
        </div>

        <div className="action-panel panel panel-default">
            <div className="panel-heading">
                <div className="panel-title">Action</div>
            </div>
            <div className="panel-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2">Service:</div>
                        <div className="col-md-10">WebHooks</div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">Action:</div>
                        <div className="col-md-10">Make a web request</div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">URL:</div>
                        <div className="col-md-10">http://{window.location.hostname}/api/watering/zone/{'{{NumberField}}'}?token=<code>&lt;API Security Token&gt;</code></div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">Method:</div>
                        <div className="col-md-10">PUT</div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">Content Type:</div>
                        <div className="col-md-10">application/json</div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">Body:</div>
                        <div className="col-md-10"><code>{'{ "minutes": 15 }'}</code></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
);

export default SetupPage;
